import { Component, inject, Input } from '@angular/core';
import { DatePipe, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { MatCard, MatCardContent, MatCardFooter } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import {
  MatList,
  MatListItem,
  MatListItemIcon,
  MatListItemTitle,
} from '@angular/material/list';
import { ShippingMethodPipe } from '../../../core/pipes/shipping-method.pipe';
import { RedemptionResponse } from '../../../core/Interfaces/Http/redemption-response';
import { ProductItemComponent } from '../product-item/product-item.component';
import { StockItemComponent } from '../stock-item/stock-item.component';
import { MatButton, MatFabButton } from '@angular/material/button';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RedemptionService } from '../../../core/services/redemption.service';
import { RedemptionStatusEnum } from '../../../core/enums/redemption-status.enum';

@Component({
  selector: 'app-fetch',
  standalone: true,
  imports: [
    DatePipe,
    MatCard,
    MatCardContent,
    MatIcon,
    MatList,
    MatListItem,
    MatListItemIcon,
    MatListItemTitle,
    NgForOf,
    NgOptimizedImage,
    ShippingMethodPipe,
    ProductItemComponent,
    StockItemComponent,
    MatButton,
    NgIf,
    MatCardFooter,
    MatFabButton,
  ],
  templateUrl: './fetch.component.html',
  styleUrl: './fetch.component.scss',
})
export class FetchComponent {
  @Input() redemption!: RedemptionResponse;
  readonly dialog = inject(MatDialog);

  constructor(private redemptionService: RedemptionService) {}

  openConfirmDialog(status: RedemptionStatusEnum, context: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '100ms',
      data: {
        context: context,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.redemptionService.updateStatus(
          this.redemption.redemption.orderID,
          status,
        );
      }
    });
  }

  protected readonly RedemptionStatusEnum = RedemptionStatusEnum;
}
