@if (settings) {
  <div class="pt-4 pb-4">
    <mat-card>
      <mat-label class="p-2">Viivakoodin lukijan input: autofocus</mat-label>
      <section>
        <mat-checkbox [checked]="settings.autofocus" (click)="change()"></mat-checkbox>
      </section>
    </mat-card>
  </div>
}

