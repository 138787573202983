@if (settings) {
  <div class="pt-4 pb-4">
    <mat-card>
      <mat-label class="p-2">Käytettävä kamera</mat-label>
      <mat-select [(value)]="selectedDeviceID" [disabled]="!foundDevices">
        @for (device of deviceList; track device) {
          <mat-option [value]="device.deviceId" (click)="change()">{{device.label}}</mat-option>
        }
      </mat-select>
    </mat-card>
  </div>
}
