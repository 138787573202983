<mat-card class="m-4">
  <mat-card-content>
    <div class="flex flex-justify flex-wrap">
      <span matListItemTitle class="w-full md:w-1/2">NOUTO {{redemption.redemption.orderID}}</span>
      <span class="w-full md:w-1/2">
        <div class="flex flex-justify flex-wrap">
          <div class="w-full md:w-1/2"></div>
          <div class="w-full md:w-1/2">{{redemption.redemption.redeemedAt*1000 | date:'H:mm MM.d.y'}}</div>
        </div>
      </span>
    </div>
    <mat-list role="list" *ngFor="let product of redemption.products">
      <app-product-item [product]="product"></app-product-item>
    </mat-list>
  </mat-card-content>
  <mat-card-footer>
    <ng-container *ngIf="redemption.redemption.status !== RedemptionStatusEnum.COMPLETED">
      <button mat-fab extended color="primary"
              (click)="openConfirmDialog(RedemptionStatusEnum.COMPLETED, 'Lunastus noudettu')">
        Päivitä lunastus noudetuksi
      </button>
    </ng-container>
<!--    <ng-container *ngIf="redemption.redemption.status === RedemptionStatusEnum.COMPLETED">-->
<!--      <button mat-fab extended color="warn"-->
<!--              (click)="openConfirmDialog(RedemptionStatusEnum.PENDING_PICKUP, 'Päivitä lunastus odottaa noutoa')">-->
<!--        Päivitä lunastus odottaa noutoa-->
<!--      </button>-->
<!--    </ng-container>-->
  </mat-card-footer>
</mat-card>
