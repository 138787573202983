import { Injectable } from '@angular/core';
import { RedemptionResponse } from '../Interfaces/Http/redemption-response';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  catchError,
  Observable,
  ReplaySubject,
  Subject,
  throwError,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import { RedemptionStatusEnum } from '../enums/redemption-status.enum';

@Injectable({
  providedIn: 'root',
})
export class RedemptionService {
  private api: string = environment.api_url;
  private env: string = environment.env;
  private redemptions: ReplaySubject<RedemptionResponse> = new ReplaySubject();
  private clearSubject: Subject<boolean> = new Subject();
  constructor(private http: HttpClient) {
    const latest_redemption: string | null =
      localStorage.getItem('latest_redemption');
    if (latest_redemption !== null) {
      const red = JSON.parse(latest_redemption);
      this.getRedemption(red);
    }
  }

  updateStatus(id: string, status: RedemptionStatusEnum): boolean {
    this.http
      .get(`${this.api}/api/redemption/warehouse/update/status/${status}/${id}`)
      .pipe(catchError(this.handleError))
      .subscribe((response) => {
        // this.updateRedemption(id);
        this.clearSubject.next(true);
        return !!response;
      });
    return false;
  }

  clear(): Subject<boolean> {
    return this.clearSubject;
  }

  updateRedemption(id: string): void {
    return this.getRedemption(id);
  }

  getRedemption(id: string): void {
    this.http
      .get<RedemptionResponse>(
        `${this.api}/api/redemption/warehouse/V2/items/${id}`,
      )
      .pipe(catchError(this.handleError))
      .subscribe((response) => {
        localStorage.setItem('latest_redemption', JSON.stringify(id));
        this.redemptions.next(response);
      });
  }

  getRedemptions(): ReplaySubject<RedemptionResponse> {
    return this.redemptions;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error,
      );
    }
    return throwError(
      () => new Error('Something bad happened; please try again later.'),
    );
  }
}
