export enum RedemptionStatusEnum {
  PENDING = 'pending',
  PENDING_PICKUP = 'pending_pickup',
  COMPLETED = 'completed',
  DELETED = 'deleted',

  UNIFAUN_SEND = 'unifaun',
  UNIFAUN_QUE = 'unifaun_que',
  UNIFAUN_ERROR = 'unifaun_error',

  CANCELLED = 'cancelled',
  ON_HOLD = 'on_hold',

  SCHENKER_SEND = 'schenker',
  SCHENKER_QUE = 'schenker_que',
  SCHENKER_ERROR = 'schenker_error',

  POSTNORD_SEND = 'postnord',
  POSTNORD_QUE = 'postnord_que',
  POSTNORD_ERROR = 'postnord_error',

  BUDBEE_SEND = 'budbee',
  BUDBEE_QUE = 'budbee_que',
  BUDBEE_ERROR = 'budbee_error',
}
