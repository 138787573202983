import { Component, OnDestroy } from '@angular/core';
import { MatCard } from '@angular/material/card';
import { MatCheckbox } from '@angular/material/checkbox';
import { SettingsService } from '../../../core/services/settings.service';
import { Settings } from '../../../core/Interfaces/settings';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatLabel } from '@angular/material/form-field';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-settings-autofocus',
  standalone: true,
  imports: [
    MatCard,
    MatCheckbox,
    AsyncPipe,
    FormsModule,
    JsonPipe,
    MatLabel,
    MatOption,
    MatSelect,
  ],
  templateUrl: './autofocus.component.html',
  styleUrl: './autofocus.component.scss',
})
export class AutofocusComponent implements OnDestroy {
  sub;
  settings: Settings | undefined;

  constructor(private settingsService: SettingsService) {
    this.sub = this.settingsService.getSubject().subscribe((value) => {
      this.settings = value;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  change() {
    if (!this.settings) {
      return;
    }
    this.settingsService.updateAutofocus(!this.settings.autofocus);
  }
}
