import { Injectable } from '@angular/core';
import { Settings } from '../Interfaces/settings';
import { BehaviorSubject } from 'rxjs';
import packageJson from '../../../../package.json';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private readonly currentSettings: BehaviorSubject<Settings>;
  constructor() {
    const settingsString: string | null = localStorage.getItem('settings');
    let settings: Settings = this.generateSettings();
    if (settingsString !== null) {
      settings = JSON.parse(settingsString);
    }
    this.currentSettings = new BehaviorSubject<Settings>(settings);
    if ((settings.version ?? null) !== packageJson.version) {
      this.migrate();
    }
    // Save settings on change.
    this.currentSettings.subscribe((value) => {
      localStorage.setItem('settings', JSON.stringify(value));
    });
  }

  private generateSettings(): {
    redemptionQueSize: number;
    autofocus: boolean;
    version: string;
    selectedCamera: undefined;
  } {
    return {
      version: packageJson.version,
      autofocus: false,
      selectedCamera: undefined,
      redemptionQueSize: 1,
    };
  }

  private migrate(): void {
    const settings = this.getValue();
    if ((settings.version ?? 'undefined') === 'undefined') {
      settings.redemptionQueSize = 1;
      settings.version = packageJson.version;
      this.update(settings);
    }
  }

  getValue(): Settings {
    return this.currentSettings.getValue();
  }

  getSubject(): BehaviorSubject<Settings> {
    return this.currentSettings;
  }

  update(settings: Settings): void {
    this.currentSettings.next(settings);
  }

  updateAutofocus(value: boolean) {
    const settings = this.getValue();
    settings.autofocus = value;
    this.update(settings);
  }

  updateCamera(value: MediaDeviceInfo) {
    const settings = this.getValue();
    settings.selectedCamera = value;
    this.update(settings);
  }

  updateQueSize(value: number) {
    const settings = this.getValue();
    settings.redemptionQueSize = value;
    this.update(settings);
  }

  reset(): void {
    this.currentSettings.next(this.generateSettings());
  }
}
