import { Component, Inject, inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { ConfirmData } from '../../../core/Interfaces/confirm-data';

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogTitle,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss',
})
export class ConfirmDialogComponent {
  context: string = 'Confirm';

  readonly dialogRef = inject(MatDialogRef<ConfirmDialogComponent>);
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmData) {
    this.context = data.context ?? 'Confirm';
  }
}
