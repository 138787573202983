<div class="absolute right-0 top-0 z-40">
  <div class="flex flex-col">
    <div class="m-2">
      <button mat-fab (click)="backClicked()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>

<div class="absolute right-0 bottom-0 z-40">
  <div class="flex flex-col">
    <div class="m-2">
<!--      <button mat-fab color="warn" (click)="reset()">-->
      <button mat-fab color="warn" (click)="openConfirmResetDialog('Palauta asetukset oletus asetuksiin.')">
        <mat-icon>restart_alt</mat-icon>
      </button>
    </div>
  </div>
</div>

<div class="xl:flex xl:justify-center">
  <div class="flex flex-col xl:p-20 xl:w-3/5">
    <div class="p-8">
      <div class="flex justify-between">
        <h1>Asetukset</h1>
        <h2 class="mr-10 md:mr-0">App version: {{version}}</h2>
      </div>

    </div>

    <div class="md:p-4 xl:p-8">
      <app-settings-autofocus></app-settings-autofocus>
      <app-settings-camera></app-settings-camera>
      <app-settings-redemptions-log-size></app-settings-redemptions-log-size>
    </div>
  </div>
</div>


