import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import {
  BarcodeScannerLivestreamComponent,
  BarcodeScannerLivestreamModule,
} from 'ngx-barcode-scanner';
import { QuaggaJSResultObject } from '@ericblade/quagga2';
import { RedemptionService } from '../../core/services/redemption.service';
import { Settings } from '../../core/Interfaces/settings';
import { SettingsService } from '../../core/services/settings.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cam-reader',
  standalone: true,
  imports: [BarcodeScannerLivestreamModule],
  templateUrl: './cam-reader.component.html',
  styleUrl: './cam-reader.component.scss',
})
export class CamReaderComponent implements AfterViewInit, OnDestroy {
  sub: Subscription | undefined;
  @ViewChild(BarcodeScannerLivestreamComponent)
  barcodeScanner: BarcodeScannerLivestreamComponent | undefined;
  settings: Settings | undefined;
  barcodeValue: string = '';
  deviceId: string = '';
  stop: boolean = false;

  constructor(
    private redemptionService: RedemptionService,
    private settingsService: SettingsService,
  ) {
    this.sub = this.settingsService.getSubject().subscribe((value) => {
      this.settings = value;
    });
    if (this.settings?.selectedCamera?.deviceId) {
      this.deviceId = this.settings.selectedCamera.deviceId;
    }
  }

  ngAfterViewInit() {
    if (this.barcodeScanner instanceof BarcodeScannerLivestreamComponent) {
      this.barcodeScanner.deviceId = this.deviceId;
      this.barcodeScanner.start().then(() => {
        // If user starts camera but stop it before really starting it would stay on.
        // just using stop to check if needed to stop later.
        if (this.stop) {
          this.barcodeScanner?.stop();
        }
      });
    }
  }

  ngOnDestroy() {
    this.stop = true;
    if (this.barcodeScanner) {
      this.barcodeScanner.stop();
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  onValueChanges(result: QuaggaJSResultObject) {
    const code = result.codeResult.code;

    if (!code) {
      return;
    }
    if (this.barcodeValue === code) {
      return;
    }
    this.barcodeValue = code;
    this.redemptionService.getRedemption(this.barcodeValue);
  }
}
